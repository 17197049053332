<template>
  <div class="autocomplete-field form-group">
    <legend
      class="bv-no-focus-ring col-form-label pt-0"
    >Водитель</legend>
    <VueAutosuggest
      :value="suggestionValue"
      :suggestions="suggestionsList"
      :input-props="{
        placeholder: 'Водитель',
        class: 'form-control',
      }"
      component-attr-class-autosuggest-results-container="autocomplete-field-dropdown"
      :get-suggestion-value="displayDiver"
      @input="onInputHandler"
      @selected="onSelectedHandler"
    >
      <template v-slot="{suggestion}">
        <span>
          {{ suggestion.item.last_name }}
          {{ suggestion.item.first_name }}
          {{ suggestion.item.middle_name }}
          {{ suggestion.item.email }}
        </span>
      </template>
    </VueAutosuggest>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    VueAutosuggest,
  },
  props: {
    filter: {
      type: Object,
      default() {
        return {}
      },
    },
    value: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      driversList: [],
      inputHandlerTimeout: null,
      driversListLoad: false,
      driverSelected: null,
    }
  },
  computed: {
    suggestionsList() {
      return [{
        data: this.driversList,
      }]
    },
    suggestionValue() {
      if (this.driverSelected === null) return ''
      return `${this.driverSelected.first_name} ${this.driverSelected.last_name} ${this.driverSelected.middle_name} ${this.driverSelected.email}`
    },
    requestFilter() {
      return Object.keys(this.filter).map(key => `filter[${key}]=${this.filter[key]}`).join('&')
    },
  },
  watch: {
    value: {
      deep: true,
      handler(nv) {
        this.driverSelected = nv
      },
    },
  },
  methods: {
    searchDrivers(data) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.$axios.get(`/drivers?search=${data}&page_size=10&${this.requestFilter}`)
        .then(response => {
          this.driversList = response.data.data
        }).catch().finally(() => {
          this.loading = false
        })
    },
    onInputHandler(data) {
      this.driversList = []
      if (data.length <= 3) return
      clearTimeout(this.inputHandlerTimeout)
      this.inputHandlerTimeout = setTimeout(
        () => {
          this.searchDrivers(data)
        },
        300,
      )
    },
    displayDiver(suggest) {
      return `${suggest.item.first_name} ${suggest.item.last_name} ${suggest.item.middle_name} ${suggest.item.email}`
    },
    onSelectedHandler(suggest) {
      this.$emit('input', suggest.item)
      this.driversList = []
    },
  },
}
</script>
