<template>
  <div>
    <b-card>
      <v-table
        ref="sheets-table"
        path="/cars/sheets?populate=car,driver"
        :fields="SHEETS_TABLE_FIELDS"
        :filter="{ ...sheetFilterForm, search }"
        @create="onCreateHandler"
      >
        <template #cell(serial)="data">
          <v-table-serial-column :data="data" />
        </template>
        <template #cell(actions)="{ item }">
          <span
            class="text-primary cursor-pointer"
            @click="onPrintHandler(item)"
          >
            <feather-icon
              icon="PrinterIcon"
            />
          </span>
        </template>
      </v-table>
    </b-card>
    <b-modal
      ref="sheet-modal"
      title="Создать путевой лист"
      @ok.prevent="onModalOkHandler"
      @cancel="resetSheetForm"
      @shown="onModalShown"
    >
      <div class="autocomplete-field form-group">
        <legend
          class="bv-no-focus-ring col-form-label pt-0"
        >Автомобиль</legend>
        <vue-autosuggest
          :suggestions="carListOptions"
          :input-props="{ class: 'form-control', placeholder: 'Выберите автомобиль' }"
          :get-suggestion-value="getCarSuggestValue"
          component-attr-class-autosuggest-results-container="autocomplete-field-dropdown"
          @input="(value) => carListQuery = value"
          @selected="onCarSelectedHandler"
        >
          <template v-slot="{ suggestion }">
            <span>
              {{ suggestion.item.mark }} {{ suggestion.item.model }} {{ suggestion.item.number }}
            </span>
          </template>
        </vue-autosuggest>
      </div>
      <driver-select-field
        :filter="{ has_car_contract: 1 }"
        :value="sheetFormDriver"
        @input="(driver) => sheetForm.driver_id = driver.id"
      />
      <b-form-group
        label="Дата путевого листа"
      >
        <div class="row">
          <div class="col">
            <b-form-datepicker
              v-model="sheetForm.date_start"
              :date-format-options="{ year: 'numeric', day: '2-digit', month: '2-digit'}"
            />
          </div>
          <!--
          <div class="col">
            <b-form-datepicker
              v-model="sheetForm.date_end"
              :date-format-options="{ year: 'numeric', day: '2-digit', month: '2-digit'}"
            />
          </div>
          -->
        </div>
      </b-form-group>
      <b-form-group
        label="Пробег"
      >
        <div class="row">
          <div class="col">
            <b-form-input
              v-model="sheetForm.mileage_start"
            />
          </div>
          <div class="col">
            <b-form-input
              v-model="sheetForm.mileage_end"
            />
          </div>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BFormGroup,
  BFormDatepicker,
  BFormInput,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import VTable, {
  VTableSerialColumn,
} from '@/views/components/vtable'
import moment from 'moment'
import DriverSelectField from '@/views/components/DriverSelectField.vue'
import { SHEETS_TABLE_FIELDS } from './sheets_use'

const DEFAULT_SHEET_FORM = {
  car_id: null,
  driver_id: null,
  date_start: null,
  date_end: null,
  mileage_start: 0,
  mileage_end: null,
}

export default {
  components: {
    BCard,
    BModal,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    VTable,
    VTableSerialColumn,
    DriverSelectField,
    VueAutosuggest,
  },
  data() {
    return {
      sheetFilterForm: {},
      sheetForm: {},
      sheetFormDriver: null,
      carsList: [],
      carListQuery: '',
      SHEETS_TABLE_FIELDS,
    }
  },
  computed: {
    carListOptions() {
      if (this.carListQuery.length < 2) {
        return [{ data: [] }]
      }
      return [{ data: this.carsList.filter(item => item.number.toLowerCase().indexOf(this.carListQuery.toLowerCase()) > -1) }]
    },
    search() {
      return this.$store.getters['app/search']
    },
  },
  watch: {
    'sheetForm.car_id': {
      handler(value) {
        this.carsList.forEach(car => {
          if (car.id === value) {
            this.sheetForm.mileage_start = car.mileage
          }
        })
      },
    },
  },
  mounted() {
    if (this.$route.query) {
      this.sheetFilterForm = this.$route.query
    }
    this.resetSheetForm()
  },
  methods: {
    onModalShown() {
      if (this.carsList.length === 0) {
        this.fetchCarsList()
      }
    },
    getCarSuggestValue({ item }) {
      return `${item.mark} ${item.model} ${item.number}`
    },
    onCarSelectedHandler({ item }) {
      this.sheetForm.car_id = item.id
      if (!item.group_id && item.sheet && item.sheet.status !== 'NEW' && item.sheet_driver) {
        this.sheetForm.prev_sheet_id = item.sheet.id
        this.sheetForm.driver_id = item.sheet_driver.id
        this.sheetFormDriver = item.sheet_driver
      }
    },
    resetSheetForm() {
      this.sheetForm = { ...DEFAULT_SHEET_FORM }
      this.sheetFormDriver = null
      this.sheetForm.date_start = moment().format('YYYY-MM-DD')
    },
    fetchCarsList() {
      this.$axios.get('/cars?populate=sheet,sheetDriver&page_size=100&filter[group_id__null]=1').then(response => {
        this.carsList = response.data.data
      }).catch(() => {})
    },
    onCreateHandler() {
      this.$refs['sheet-modal'].show()
    },
    onModalOkHandler() {
      const data = {
        ...this.sheetForm,
        date_start: `${this.sheetForm.date_start} 00:00:00`,
      }
      if (data.prev_sheet_id) {
        data.date_end = `${moment(data.date_start).add(6, 'day').format('YYYY-MM-DD')} 23:59:59`
      } else {
        data.date_end = moment(data.date_start).add(12, 'hour').format('YYYY-MM-DD hh:mm:ss')
      }
      this.$axios.post('/cars/sheets', data).then(() => {
        this.$refs['sheet-modal'].hide()
        this.resetSheetForm()
        this.carsList = []
        this.$refs['sheets-table'].refresh()
      })
    },
    onPrintHandler(item) {
      this.$axios.post('/print/sheet', {
        id: item.id,
      }, { responseType: 'blob' })
    },
  },
}
</script>
