import moment from 'moment'

export const SHEETS_TABLE_FIELDS = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'driver',
    label: 'Водитель',
    sortable: true,
    formatter(driver) {
      return `${driver.last_name} ${driver.first_name} ${driver.middle_name}`
    },
  },
  {
    key: 'car',
    label: 'Автомобиль',
    formatter(car) {
      return `${car.mark} ${car.model} ${car.number}`
    },
    sortable: true,
  },
  {
    key: 'rent_date',
    label: 'Даты',
    sortable: true,
    formatter(...args) {
      const obj = args[2]
      const dateStart = moment(obj.date_start).format('DD.MM.YYYY')
      const dateEnd = moment(obj.date_end).format('DD.MM.YYYY')
      return `${dateStart} / ${dateEnd}`
    },
  },
  {
    key: 'actions',
    label: '',
  },
]
